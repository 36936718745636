<template>
  <div class="o-body">
    <div class="o-body__wrapper">
      <OBodyMessages id="messages">
        <OMessage
          v-for="(message, index) in messages"
          :message="message"
          :key="index"
          @onSetMessage="onSetMessage"
        />
      </OBodyMessages>
      <div class="o-body__input-container">
        <ATextArea
          v-model="prompt"
          :disabled="isLoading"
          :is-auto-rows="true"
          :max-rows="5"
          @onEnter="onClickSend"
        />
        <AButtonSend
          v-if="!isLoading"
          @click="onClickSend"
          :disabled="isLoading || !prompt"
        />
        <ProgressSpinner
          v-else
          style="width: 50px;
          height: 50px"
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".7s"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch, defineProps, onBeforeMount, computed, onMounted } from 'vue';
import * as uuid from 'uuid';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import ATextArea from '../a-textarea';
import OMessage from '../o-message'
import OBodyMessages from './o-body-messages'
import AButtonSend from '../a-button-send/a-button-send.vue';

import { BACKEND_URL } from '../../api/backend-client';
import {
  generateCharts,
  getConversation,
  createConversation,
  chatInit,
} from '../../api/conversation.api';
import { useScroll } from './use-scroll';

import {
  CLIENT_NAME,
  CLIENT_NAMES,
  SUBST_BOT_NAME,
  suggestedQuestions,
  SHOW_SWITCH,
} from '../../constants'
import { useConversation } from '../../composables/useConversation';

const props = defineProps({
  conversation: {
    type: Object,
    default: () => null,
  },
})

const {
  scrollDetect,
  scrollToBottom,
} = useScroll()

const store = useStore()
const route = useRoute()
const { getConversations } = useConversation()

const userUuid = ref(null)
const prompt = ref('')
const messages = ref([])
const isLoading = ref(false)
const conversationUuid = ref(null)
const routeConversationUuid = computed(() => route.query.conversationUuid)
const routeDescriptorBasePath = computed(() => route.query.descriptorBasePath)
const routeBasePath = computed(() => route.query.basePath)
const routeFilename = computed(() => route.query.filename)
const routeUpdatedAt = computed(() => Number.parseInt(Number.parseInt(route.query.updatedAt || '0') / 1000))
const messageUuid = ref(null)
userUuid.value = localStorage.getItem('userUuid')
const generatedQuestions = ref([])

const getUseCache = () => SHOW_SWITCH === true && localStorage.getItem('useCache') === 'true'

const generateInitialMessage = () => {
  const message = {
    uuid: uuid.v4(),
    type: 'system',
    title: `<b>${SUBST_BOT_NAME} AI Analyst, how can I help you?</b>`,
    isLoading: false,
    questions: routeBasePath.value ? [] : suggestedQuestions,
  }
  return message
}

const getMessageCharts = async (message_uuid) => {
  const { data } = await generateCharts({
    uuid: message_uuid,
    user_uuid: userUuid.value,
    conversation_uuid: routeConversationUuid.value || conversationUuid.value,
  })
  return data.charts
}

const updateMessagesCharts = () => {
  messages.value.forEach(async (message) => {
    const charts = await getMessageCharts(message.uuid)
    message.charts = charts
  })
}

const handleGetConversation = async ({ conversation_uuid }) => {
  if (!conversation_uuid) return

  const { data } = await getConversation({
      uuid: conversation_uuid,
      user_uuid: userUuid.value,
  })
  messages.value = data.messages.map((message) => {
    let textTechDetails = ''
    let textSummary = ''
    if (message.answer) {
      const text = message.answer.replace(/<div class="tech-details-start"\/>/g, '')
      const textParts = text.split('<div class="tech-details-end"/>')
      if (textParts.length > 1) {
        textTechDetails = textParts[0]
        textSummary = textParts[1]
      } else {
        textSummary = textParts[0]
      }
    }
    return {
      ...message,
      type: message.role,
      prompt: message.prompt,
      textTechDetails,
      textSummary,
      isLoading: false,
      charts: [],
    }
  })

  updateMessagesCharts()
  if (data.messages.length === 0) {
    messages.value.push(generateInitialMessage())
    messages.value[0].questions = routeBasePath.value ? generatedQuestions.value : suggestedQuestions
  }
}

watch(() => props.conversation, (val1, val2) => {
  console.log(props.conversation)
  if (val1 === val2) return
  handleGetConversation({ conversation_uuid: props.conversation?.uuid })
  console.log(props.conversation)
})

messages.value.push(generateInitialMessage())

const initMessage = () => reactive({
  type: null,
  title: '',
  text: '',
  textTechDetails: '',
  textSummary: '',
  isTextTechDetails: true,
  prompt: '',
  query: '',
  data: null,
  summary: '',
  charts: null,
  isLoading: false,
  isLoadingCharts: false,
  error: null,
})

let currentMessage = initMessage()

const handleEventSourceWithBody = async (messageKey, endpoint) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      conversation: {
        uuid: routeConversationUuid.value || conversationUuid.value,
        user_uuid: userUuid.value,
        descriptor_base_path: routeDescriptorBasePath.value,
        base_path: routeBasePath.value,
        file_name: routeFilename.value,
      },
      message: {
        uuid: messageUuid.value,
        user_uuid: userUuid.value,
        conversation_uuid: routeConversationUuid.value || conversationUuid.value,
        prompt: String(prompt.value),
        use_cache: getUseCache(),
      }
    })
  })

     // eslint-disable-next-line no-undef
  const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
  // eslint-disable-next-line no-constant-condition
  while (true) {
    let { value, done } = await reader.read();
    if (done) break;

    let techDetailsStart = false;
    let techDetailsEnd = false;

    if (value?.includes('<div class="tech-details-start"/>')) {
      techDetailsStart = true
      currentMessage.isTextTechDetails = true
    }

    if (value?.includes('<div class="tech-details-end"/>')) {
      techDetailsEnd = true
      currentMessage.isTextTechDetails = false
    }

    if (techDetailsStart && techDetailsEnd) {
      let splitted1 = value.split('<div class="tech-details-start"/>')
      let splitted2 = splitted1[1].split('<div class="tech-details-end"/>')
      let techDetails = splitted2[0]
      let textSummary = splitted2[1]
      currentMessage.textTechDetails += `${techDetails}`
      currentMessage.textSummary += `${textSummary}`
    } else if (techDetailsStart) {
      let splitted1 = value.split('<div class="tech-details-start"/>')
      let techDetails = splitted1[1]
      currentMessage.textTechDetails += `${techDetails}`
    } else if (techDetailsEnd) {
      let splitted1 = value.split('<div class="tech-details-end"/>')
      let techDetails = splitted1[0]
      let textSummary = splitted1[1]
      currentMessage.textTechDetails += `${techDetails}`
      currentMessage.textSummary += `${textSummary}`
    } else {
      if (value && currentMessage.isTextTechDetails && value !== '<div class="tech-details-start"/>') {
        currentMessage.textTechDetails += `${value}`
      }
      if (value && !currentMessage.isTextTechDetails && value !== '<div class="tech-details-end"/>') {
        currentMessage.textSummary += `${value}`
      }
    }

    scrollToBottom('messages')
    console.log('Received',  value);
  }
}

const handleGetStreamAnswer = async () => {
  await handleEventSourceWithBody('text', `${BACKEND_URL}/conversation/stream`)
}

const handleCreateConversation = async () => {
  const { data } = await createConversation({
    conversation: {
      uuid: routeConversationUuid.value || conversationUuid.value,
      user_uuid: userUuid.value,
      descriptor_base_path: routeDescriptorBasePath.value,
      base_path: routeBasePath.value,
      file_name: routeFilename.value,
    },
    message: {
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
      prompt: String(prompt.value),
    }
  })
  return data.charts
}

const handleCfo = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    getConversations()
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    currentMessage.isLoadingCharts = true
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    const resCharts = await generateCharts({
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
    })
    currentMessage.charts = resCharts.data.charts
    scrollToBottom('messages')
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const handleCfoTbi = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    getConversations()
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    currentMessage.isLoadingCharts = true
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
    const resCharts = await generateCharts({
      uuid: messageUuid.value,
      user_uuid: userUuid.value,
      conversation_uuid: routeConversationUuid.value || conversationUuid.value,
    })
    currentMessage.charts = resCharts.data.charts
    scrollToBottom('messages')
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const handleLegalNodes = async () => {
  try {
    currentMessage.isLoading = true
    await handleCreateConversation()
    getConversations()
    messageUuid.value = uuid.v4()
    await handleGetStreamAnswer()
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
  } catch (e) {
    currentMessage.error = "Something happen, please try again"
    console.error(e)
  }
}

const clientFlowHandlers = {
  [CLIENT_NAMES.DATRICS]: handleCfo,
  [CLIENT_NAMES.CFO]: handleCfo,
  [CLIENT_NAMES.CFO_TBI]: handleCfoTbi,
  [CLIENT_NAMES.SIGMA]: handleCfoTbi,
  [CLIENT_NAMES.LEGAL_NODES]: handleLegalNodes,
}

const onSetMessage = (message) => {
  prompt.value = message
}

const onClickSend = async () => {
  isLoading.value = true
  if (!conversationUuid.value && !routeConversationUuid.value) {
    conversationUuid.value = uuid.v4()
  }
  messageUuid.value = uuid.v4()
  messages.value.push({
    type: 'user',
    prompt: prompt.value,
  })
  currentMessage = initMessage()
  messages.value.push(currentMessage)

  try {
    await clientFlowHandlers[CLIENT_NAME]()
  } catch (e) {
    console.log(e)
  } finally {
    prompt.value = null
    isLoading.value = false
    currentMessage.isLoading = false
    currentMessage.isLoadingCharts = false
    setTimeout(() => {
      scrollToBottom('messages')
    }, 200)
  }
}

async function onOpenPage() {
  if (routeBasePath.value) {
    try {
      prompt.value = "Data preparation, please wait..."
      isLoading.value = true
      const res = await chatInit({
        descriptor_base_path: routeDescriptorBasePath.value,
        base_path: routeBasePath.value,
        file_name: routeFilename.value,
        updated_at: routeUpdatedAt.value,
      })
      generatedQuestions.value = res.data.questions || []
      messages.value[0].questions = routeBasePath.value ? generatedQuestions.value : suggestedQuestions
      console.log('res.data', res.data)
    } catch (error) {
      console.log(error)
    } finally {
      prompt.value = ''
      isLoading.value = false
    }
  }
}

onBeforeMount(async () => {
  try {
    const route = useRoute()
    const { conversationUuid: cUuid } = route.query
    conversationUuid.value = cUuid
    await handleGetConversation({ conversation_uuid: conversationUuid.value })

    userUuid.value = localStorage.getItem('userUuid')
    if (!userUuid.value) {
      store.dispatch('user/createUserUuid')
    }
    await getConversations()
  } catch (error) {
    console.error(error)
  }
})
onMounted(async () => {
  await onOpenPage()
  scrollDetect('messages')
})
</script>

<style lang="scss">
@import "o-body.scss";
</style>

// Compare this year/quarter's net income (NI) with the same period of the previous year. Find factors causing changes.