import { getConversations } from '../api/conversation.api'

const conversations = {
  namespaced: true,
  state: () => ({
    conversations: [],
    currentConversation: null,
  }),
  mutations: {
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations
    },
    SET_CURRENT_CONVERSATION(state, conversation) {
      state.currentConversation = conversation
    },
  },
  actions: {
    async getConversations({ commit, rootGetters }, { basePath, filename, descriptorBasePath } = {}) {
      let options = {}
      if (basePath) {
        options.base_path = basePath
        options.file_name = filename
        options.descriptor_base_path = descriptorBasePath
      }
      const userUuid = rootGetters['user/getUserUuid']
      const { data } = await getConversations({
        user_uuid: userUuid,
        ...options,
      })
      commit('SET_CONVERSATIONS', data)
    }
  },
  getters: {
    getConversations: (state) => {
      return state.conversations || []
    },
  },
}

export default conversations