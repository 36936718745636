const config = {
  namespaced: true,
  state: () => ({
    useCache: false || localStorage.getItem('useCache') === 'true',
  }),
  mutations: {
    SET_USE_CACHE(state, useCache) {
      state.useCache = useCache
      localStorage.setItem('useCache', useCache === true ? 'true' : 'false')
    },
  },
  actions: {
    setUseCache({ commit }, useCache) {
      commit('SET_USE_CACHE', useCache)
    }
  },
  getters: {
    getUserUuid: () => {
      const userUuid = localStorage.getItem('userUuid')
      return userUuid
    }
  }
}

export default config
