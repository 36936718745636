<template>
  <textarea
    v-model="inputValue"
    type="text"
    class="a-textarea"
    :rows="computedRows"
    :disabled="disabled"
  >
  </textarea>
</template>

<script setup>
import { computed, defineProps, defineEmits, onMounted, onBeforeMount } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: () => '',
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  rows: {
    type: Number,
    default: () => 1,
  },
  /**
   * change rows count based on \n count at text
   */
  isAutoRows: {
    type: Boolean,
    default: () => false,
  },
  /**
   * value active only if props.isAutoRows: true
   */
  maxRows: {
    type: Number,
    default: () => 3,
  },
})

function countNewlines(text) {
  let count = 1;
  if (text) {
    for (let i = 0; i < text.length; i++) {
      if (text[i] === '\n') {
        count++;
      }
    }
  }
  return count;
}

const emit = defineEmits(['update:modelValue', 'onEnter', 'onCmdEnter', 'onCtrlEnter', 'onShiftEnter'])
const inputValue = computed({
  get: () => {
    return props.modelValue},
  set: (value) => emit('update:modelValue', value),
})

function getAutoRowCount() {
  const rowsCount = countNewlines(inputValue.value)
  if (rowsCount > props.maxRows) {
    return props.maxRows
  }
  return rowsCount
}

const computedRows = computed(() => {
  if (props.isAutoRows) {
    const rows = getAutoRowCount()
    return rows
  }
  return props.rows
})

function keydownHandler(event) {
  if (event.shiftKey && event.code === 'Enter') {
    emit('onShiftEnter')
  } else if (!event.metaKey && event.ctrlKey && event.code === 'Enter') {
    inputValue.value = `${inputValue.value}\n`
    emit('onCtrlEnter')
  } else if (event.metaKey && event.code === 'Enter') {
    inputValue.value = `${inputValue.value}\n`
    emit('onCmdEnter')
  } else if (event.code === 'Enter' && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
    emit('onEnter')
  }
}

onMounted(() => {
  document.addEventListener('keydown', keydownHandler)
})
onBeforeMount(() => {
  document.removeEventListener('keydown', keydownHandler)
})
</script>

<style lang="scss">
@import "a-textarea.scss";
</style>