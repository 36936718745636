import { createApp } from 'vue'

import { store } from './store'
import App from './App.vue'

import 'prismjs'
import 'prismjs/components/prism-sql.min.js'
import 'prismjs/themes/prism.css'

import PrimeVue from 'primevue/config';
import "primevue/resources/themes/lara-light-indigo/theme.css";  
import "primevue/resources/primevue.min.css";
import './assets/scss/main.scss'
import Skeleton from 'primevue/skeleton';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import router from './router'

import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';

// highlightjs
import hljs from 'highlight.js';

VueMarkdownEditor.use(githubTheme, {
  Hljs: hljs,
  extend() {
    // md is a markdown-it instance, you can modify the configuration here, and use plugin for syntax expansion
    // md.set(option).use(plugin);
  },
});

const app = createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(VueMarkdownEditor)

// eslint-disable-next-line
app.component('Skeleton', Skeleton)
app.component('DataTable', DataTable)
// eslint-disable-next-line
app.component('Column', Column)
app.component('ProgressBar', ProgressBar)
app.component('ProgressSpinner', ProgressSpinner)
app.mount('#app')