<template>
<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_19782_28047)">
<circle cx="17.7207" cy="16.2186" r="15" fill="white"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.3983 13.4867V5.52408L20.3035 4.20955C18.8018 3.26719 16.8783 3.25835 15.3676 4.18688L8.15303 8.62117C6.81314 9.44471 6 10.8835 6 12.4309V20.3215C6 21.9213 6.86879 23.4008 8.28191 24.2075L15.4978 28.3267C16.9447 29.1527 18.7363 29.1447 20.1755 28.306L27.1966 24.2139C28.5893 23.4021 29.4425 21.9338 29.4425 20.3486V12.4033C29.4425 10.871 28.6449 9.44401 27.3259 8.61631L24.7863 7.0226V21.6843H25.0967C25.5045 21.6843 25.7103 21.6944 26.022 21.8455C26.3338 21.9965 26.5064 22.3416 26.5064 22.7656C26.5064 23.1896 26.3899 23.4361 26.2318 23.5908C26.0736 23.7454 25.6502 23.8849 25.3838 23.8849L22.927 23.8852C22.639 23.8849 22.4417 23.7299 22.371 23.6445C22.3002 23.559 22.251 23.3776 22.251 23.1335V22.6712C21.7911 23.1335 21.5203 23.3067 20.7711 23.6445C20.022 23.9822 19.06 24.1864 18.186 24.1864C16.6128 24.1864 15.1579 23.5874 14.0966 22.4928C13.0353 21.3983 12.4131 19.8522 12.4131 18.2245C12.4131 16.54 12.8815 15.1382 14.0136 14.0192C15.1456 12.9002 16.8894 12.0932 18.5791 12.0932C19.4365 12.0932 20.2064 12.2284 20.889 12.5254C21.5715 12.8225 22.1215 13.1692 22.3983 13.4867ZM16.0097 15.4095C15.2864 16.0808 15.0195 17.1126 15.0195 18.0555C15.0195 18.9985 15.447 20.014 16.0097 20.5955C16.5724 21.177 17.5819 21.7302 18.6483 21.7302C19.7224 21.7302 20.7382 21.2927 21.4309 20.5955C22.1237 19.8983 22.3738 18.8548 22.3862 17.918C22.3987 16.9812 21.933 16.0044 21.2442 15.3187C20.5553 14.6329 19.7302 14.3808 18.6483 14.3808C17.5508 14.3808 16.7329 14.7381 16.0097 15.4095Z" fill="#222528"/>
<defs>
<filter id="filter0_d_19782_28047" x="0.720703" y="0.218628" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feGaussianBlur stdDeviation="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_19782_28047"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_19782_28047" result="shape"/>
</filter>
</defs>
</svg>
</template>