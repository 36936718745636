<template>
  <div class="o-body-messages">
    <slot />
  </div>
</template>

<script setup>
</script>

<style lang="scss">
@import "o-body-messages.scss";
</style>
