<template>
  <router-link :to="{ name: tab.pathName, query }">
    <div
      class="o-header-tab"
      :class="tabClass"
    >
      {{ tab.title }}
    </div>
  </router-link>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  tab: {
    type: Object,
    default: () => ({}),
  }
})
const route = useRoute()
const query = computed(() => route.query)

const tabClass = computed(() => ({
  'o-header-tab--selected': route.name === props.tab.pathName,
}))

</script>

<style lang="scss">
@import "o-header-tab.scss";
</style>
