<template>
  <div
    class="a-button-send"
    :class="buttonClass"
    @click="onClick"
  >
    <AIconSend class="button-send__icon"/>
  </div>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import AIconSend from '../a-icon-send';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: () => false,
  },
})
const emit = defineEmits(['click'])
const buttonClass = computed(() => ({
  'button-send--disabled': props.disabled,
}))
const onClick = () => {
  if (props.disabled) return
  emit('click')
}
</script>

<style lang="scss">
@import "a-button-send.scss";
</style>