<template>
  <DataTable
    :value="items"
    showGridlines
    scrollable
    scrollHeight="400px"
    :class="`p-datatable-sm`"
    tableStyle="max-width: 50rem"
  >
    <Column
      v-for="name in headerColumnNames"
      :field="name"
      :header="name"
      :key="name"
    ></Column>
  </DataTable>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  }
})

const headerColumnNames = Object.keys(props.items[0])
</script>