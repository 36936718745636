import { createStore } from 'vuex'

import user from './user'
import config from './config'
import conversations from './conversations'

export const store = createStore({
  modules: {
    user,
    config,
    conversations,
  }
})