<template>
  <PrismEditor
    class="my-editor height-200 mb-4"
    v-model="editorCode"
    :highlight="highlighter"
    line-numbers
  />
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

import prism from "prismjs";
import { PrismEditor } from "vue-prism-editor";
import "prismjs/themes/prism-tomorrow.css";
import "vue-prism-editor/dist/prismeditor.min.css";

const props = defineProps({
  modelValue: {
    type: String,
    default: () => '',
  },
})
const emit = defineEmits(['update:modelValue'])

const editorCode = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
});

const highlighter = (code) => {
  return prism.highlight(code, prism.languages.sql);
};
</script>

<style>
.my-editor {
  background: #2d2d2d;
  color: #ccc;
  font-size: 14px;
  line-height: 1.5;
  padding: 10px 5px;
  border-radius: 5px;
}

.prism-editor__textarea:focus {
  outline: none;
}

.mb-4 {
  margin-bottom: 1rem;
}
</style>
