<template>
  <div
    class="a-conversation-item"
    @click="onClick"
  >
    <slot />
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['click'])

const onClick = () => {
  emit('click')
}

</script>

<style lang="scss">
@import "a-conversation-item.scss";
</style>