import { ref } from 'vue'

export const useScroll = () => {
  const isDisabledToScroll = ref(false)

  // function throttle (callback, limit) {
  //   var waiting = false;                      // Initially, we're not waiting
  //   return function () {                      // We return a throttled function
  //     if (!waiting) {                       // If we're not waiting
  //       callback.apply(this, arguments);  // Execute users function
  //       waiting = true;                   // Prevent future invocations
  //       setTimeout(function () {          // After a period of time
  //         waiting = false;              // And allow future invocations
  //       }, limit);
  //     }
  //   }
  // }

  const debounce = (f, timeout) => {
    let timer
    let wait = false
    let wrapped = null
  
    const throttled = (...par) => {
      timer = undefined
      if (wait) wrapped(...par)
    }
  
    wrapped = (...par) => {
      if (!timer) {
        timer = setTimeout(throttled, timeout, ...par)
        wait = false
        return f(...par)
      }
      wait = true
    }
  
    return wrapped
  }

  function scrollDetect(id) {
    const element = document.getElementById(id);
  
    if (element) {
      const callback = function() {
          let currentScroll = element.scrollTop; // Get Current Scroll Value
          // console.log('scrollHeight - offsetHeight - currentScroll', element.scrollHeight - element.offsetHeight - currentScroll)
          const diff = element.scrollHeight - element.offsetHeight - currentScroll
          if (diff > 10) {
            isDisabledToScroll.value = true
          } else {
            isDisabledToScroll.value = false
          }
          // console.log('isDisabledToScroll', isDisabledToScroll.value)
      };
      element.onscroll = debounce(callback, 50)
    }
  }
  
  const scrollToBottom = (id) => {
      const element = document.getElementById(id);
      if (element && !isDisabledToScroll.value) {
        element.scroll({
          left: 0,
          top: element.scrollHeight,
          behavior: "smooth",
        })
      }
  }

  return {
    scrollDetect,
    scrollToBottom,
  }
}