import { useRoute } from "vue-router"
import { useStore } from "vuex"

export const useConversation = () => {
  const store = useStore()
  const route = useRoute()

  const getConversations = async () => {
    const basePath = route?.query?.basePath
    const filename = route?.query?.filename
    const descriptorBasePath = route?.query?.descriptorBasePath
    return await store.dispatch('conversations/getConversations', { basePath, filename, descriptorBasePath })
  }

  return {
    getConversations,
  }
}