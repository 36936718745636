<template>
  <div class="container-body">
    <OSidebar
      v-if="true"
      @onChangeConversation="onChangeConversation"
    />
    <OChatBody
      :conversation="selectedConversation"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { useStore } from 'vuex'

import OSidebar from '../../components/o-sidebar'
import OChatBody from '../../components/o-body'
import { useConversation } from '../../composables/useConversation';

const { getConversations } = useConversation()
const store = useStore()
const userUuid = ref(null)
const selectedConversation = ref(null)

onBeforeMount(async () => {
  try {
    userUuid.value = localStorage.getItem('userUuid')
    if (!userUuid.value) {
      store.dispatch('user/createUserUuid')
    }
    await getConversations()
  } catch (error) {
    console.error(error)
  }
})

const onChangeConversation = (item) => {
  selectedConversation.value = item
  console.log(selectedConversation.value)
}

</script>

<style lang="scss">
.container-body {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1px;
  padding: 1px;
  box-sizing: border-box;
}

.o-header + .container-body {
  height: calc(100% - 40px);
}
</style>