<template>
  <div
    class="a-button-new-chat"
    @click="onClick"
  >
    New chat
  </div>
</template>

<script setup>
import { defineEmits } from 'vue'

const emit = defineEmits(['click'])
const onClick = () => {
  emit('click')
}
</script>

<style lang="scss">
@import "a-button-new-chat.scss";
</style>
