
<template>
  <div class="card">
    <ProgressBar
      :value="progress"
      :pt="{
        value: { style: { background: 'linear-gradient(to right, #8e2de2, #4a00e0)' } }
      }"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";

onMounted(() => {
  startProgress();
});

onBeforeUnmount(() => {
  endProgress();
});

const progress = ref(0);
const interval = ref();
const startProgress = () => {
  interval.value = setInterval(() => {
      let newValue = progress.value + Math.floor(Math.random() * 10) + 1;
      if (newValue >= 100) {
          newValue = 100;
      }
      progress.value = newValue;
  }, 2000);
};
const endProgress = () => {
  clearInterval(interval.value);
  interval.value = null;
};
</script>