import * as uuid from 'uuid'

const user = {
  namespaced: true,
  state: () => ({
    
  }),
  mutations: {
    SET_USER_UUID(state, userUuid) {
      localStorage.setItem('userUuid', userUuid)
    }
  },
  actions: {
    createUserUuid({ commit }) {
      const userUuid = uuid.v4()
      commit('SET_USER_UUID', userUuid)
    }
  },
  getters: {
    getUserUuid: () => {
      const userUuid = localStorage.getItem('userUuid')
      return userUuid
    }
  }
}

export default user
